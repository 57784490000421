import { keysToCamel, keysToSnake } from 'modules/common/helpers/transformKeysStyle';
import { BASE_URL, baseApi } from 'store/services/base';
import {
    CheckSharingCodeRequest,
    CheckSharingCodeResponse,
    CreateShareFolderUrlRequest,
    CreateShareFolderUrlResponse,
    IUnauthSharedFolder,
    SendSharingCodeRequest,
    SharingPriceRequest,
    SharingPriceResponse,
} from './types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { RootState } from '../../index';

export const sharingApiAuth = createApi({
    reducerPath: 'sharingApiAuth',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            const {
                loginInfo: { accessFolderToken },
            } = getState() as RootState | any;

            if (accessFolderToken) {
                headers.set('authorization', `Bearer ${accessFolderToken}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAuthSharedFolder: builder.query<IUnauthSharedFolder, void>({
            query: () => ({ url: 'sharing/view/authenticated/' }),
            transformResponse: (res) => keysToCamel(res),
        }),
    }),
    tagTypes: [],
});

export const sharingApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getUnauthSharedFolder: builder.query<
            IUnauthSharedFolder & { accessToken: string },
            { cid?: string }
        >({
            query: (params) => ({ url: 'sharing/view/', params }),
            transformResponse: (res) => keysToCamel(res),
        }),

        createShareFolderUrl: builder.mutation<
            CreateShareFolderUrlResponse,
            CreateShareFolderUrlRequest
        >({
            query: ({ active, ...props }) => ({
                url: `sharing/url/create/`,
                method: 'POST',
                params: { active },
                body: keysToSnake(
                    Object.fromEntries(Object.entries(props).filter(([, val]) => !!val))
                ),
            }),
            transformResponse: (res) => keysToCamel(res),
        }),

        sendSharingCode: builder.mutation<{ id: string }, SendSharingCodeRequest>({
            query: (body) => ({
                url: `sharing/code/send/`,
                method: 'POST',
                body: keysToSnake(body),
            }),

            transformResponse: (res) => keysToCamel(res),
        }),

        checkSharingCode: builder.mutation<CheckSharingCodeResponse, CheckSharingCodeRequest>({
            query: ({ codeId, ...body }) => ({
                url: `sharing/code/${codeId}/check/`,
                method: 'POST',
                body: keysToSnake(body),
            }),

            transformResponse: (res) => keysToCamel(res),
        }),

        getSharingPrice: builder.query<SharingPriceResponse, SharingPriceRequest>({
            query: (body) => ({
                url: `/payments/sharing-price/calculate/`,
                method: 'POST',
                body: keysToSnake(body),
            }),

            transformResponse: (response) => keysToCamel(response),
        }),
    }),

    overrideExisting: true,
});

export const {
    useGetUnauthSharedFolderQuery,
    useCreateShareFolderUrlMutation,
    useSendSharingCodeMutation,
    useCheckSharingCodeMutation,
    useGetSharingPriceQuery,
} = sharingApi;

export const { useGetAuthSharedFolderQuery } = sharingApiAuth;
