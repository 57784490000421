export enum Routes {
    START_SCREEN = '/',
    SIGN_UP = '/sign-up',
    ORGANIZATION_SIGN_UP = '/sign-up-organization',
    LOG_IN = '/log-in',
    RECOVERY_PASSWORD = '/recovery',
    WALKTHROUGH = '/walkthrough',
    PAYMENT_DETAILS_SETUP = '/payment-details-setup',
    PAYMENT_METHODS = '/payment-methods',
    STORED_ACCOUNT = '/stored-account',
    WALLET_CONGRATULATIONS = '/wallet-congratulations',
    ADD_PAYMENT_METHOD = '/payment-methods/add',

    EVIDENCE = '/evidence',
    VEHICLE_RENTAL = '/vehicle-rental',
    VEHICLE_RENTAL_CAPTURE = '/vehicle-rental/capture',
    HOTEL_RENTAL = '/hotel-rental',
    HOTEL_RENTAL_CAPTURE = '/hotel-rental/capture',

    // dashboard routes
    DASHBOARD = '/dashboard',
    HOME = '/dashboard/home',
    MY_FILES = '/dashboard/my-files',
    SETTINGS = '/dashboard/settings',
    GUIDE = '/dashboard/guide',

    //settings routes
    ACCOUNT_INFO = '/account-info',
    CHANGE_ADDRESS = '/change-address',
    CHANGE_PASSWORD = '/change-password',
    DATE_AND_TIME = '/date-and-time',
    EVENTS_HISTORY = '/events-history',

    CHECKOUT = '/checkout',
    FOLDER_VIEW = '/folder-view',
    FOLDER_MAP_VIEW = '/folder-map-view',
    ALL_FOLDERS_MAP_VIEW = '/folders-map-view',
    EXTEND_STORAGE_DATE = '/extend-storage-date',
    PROOF_METADATA_VIEW = '/proof-metadata-view',
    DELETE_FILES_VIEW = '/delete-files',
    SHARE_FILES_VIEW = '/share-files',
    EXTEND_SUCCESS = '/extend-storage-success',
    PAYMENT_SUCCESS = '/payment-success',
    SHARE_SUCCESS = '/share-success',

    SHARE_TYPE_OF_COPY = '/type-of-copy',
    WHO_YOU_WANT_SHARE = '/who-you-want-to-share',
    GET_ACCESS_EMAIL = '/get-access-email',
    GET_ACCESS_CONFIRM = '/get-access-confirm',
    UNAUTH_SHARED_FOLDER_VIEW = '/unauth-shared-folder',
    AUTH_SHARED_FOLDER_VIEW = '/auth-shared-folder',

    TRANSACTION_INFO = '/transaction-info',

    DELETING_OF_PROOFKEEP_ACCOUNT = '/deleting-of-proofkeep-account',
    DELETE_MY_ACCOUNT_REASONS = '/deleting-my-account-reasons',
    DELETE_MY_ACCOUNT_CONFIRM = '/deleting-my-account-confirm',

    REFER_A_FRIEND = '/refer-a-friend',
    ABOUT_REFER_PROGRAM = '/about-refer-program',
    REFERRAL_CODE = '/referral-code',
    ORGANIZATION_PARTICIPANTS = '/organization-participants',

    REFER_BY_SMS = '/refer/sms',
    REFER_BY_EMAIL = '/refer/email',

    LEGAL = '/legal',
    TERMS = '/legal/terms',
    POLICY = '/legal/policy',
    FAQ = '/faq',
    TECH_SUPPORT = '/tech-support',
    CONTACT_US = '/contact-us',
}
