import React, { lazy, Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { Routes } from 'router/routesConfig';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import BackdropLoader from 'modules/common/components/BackdropLoader';
import { Route } from './Route';

const StartScreen = lazy(() => import('modules/sign-up/screens/StartScreen'));
const WhoYouWantShare = lazy(() => import('modules/file-sharing/pages/WhoYouWantShare'));
const TransactionInfo = lazy(() => import('modules/transaction-info'));
const SignUp = lazy(() => import('modules/sign-up'));
const OrganizationSignUp = lazy(() => import('modules/organization-sign-up'));
const LogIn = lazy(() => import('modules/log-in'));
const Walkthrough = lazy(() => import('modules/walkthrough/pages/Walkthrough'));
const PaymentInfo = lazy(() => import('modules/sign-up/screens/PaymentInfo'));
const PaymentMethods = lazy(() => import('modules/settings/pages/PaymentMethods'));
const StoredAccount = lazy(() => import('modules/settings/pages/StoredAccount'));
const AddPaymentMethod = lazy(() => import('modules/settings/pages/AddPaymentMethod'));
const RecoveryPassword = lazy(() => import('modules/recovery-password'));
const Evidence = lazy(() => import('modules/evidence'));
const CheckoutPage = lazy(() => import('modules/checkout'));
const DashboardPage = lazy(() => import('modules/dashboard'));
const VehicleRentalPage = lazy(() => import('modules/vehicle-rental'));
const VehicleRentalCapture = lazy(
    () => import('modules/vehicle-rental/pages/CaptureConditionScreen')
);
const HotelRentalPage = lazy(() => import('modules/hotel-rental'));
const HotelRentalCapture = lazy(() => import('modules/hotel-rental/pages/CaptureConditions'));
const FolderView = lazy(() => import('modules/my-files/pages/FolderView'));
const DeleteFilesView = lazy(() => import('modules/my-files/pages/DeleteFilesView'));
const FolderMapPointsView = lazy(() => import('modules/my-files/pages/FolderMapPointsView'));
const FoldersMapPointsView = lazy(() => import('modules/my-files/pages/FoldersMapPointsView'));
const ExtendSuccess = lazy(() => import('modules/my-files/pages/ExtendSuccess'));
const AccountInfo = lazy(() => import('modules/settings/pages/AccountInfo'));
const DateAndTime = lazy(() => import('modules/settings/pages/DateAndTime'));
const ChangeAddress = lazy(() => import('modules/settings/pages/ChangeAddress'));
const EventsHistory = lazy(() => import('modules/settings/pages/EventsHistory'));
const ChangePassword = lazy(() => import('modules/settings/pages/ChangePassword'));
const ExtendStorageDateView = lazy(() => import('modules/my-files/pages/ExtendStorageDateView'));
const ProofMetadataView = lazy(() => import('modules/my-files/pages/ProofMetadataView'));
const WalletCongratulations = lazy(() => import('modules/settings/pages/WalletCongratulations'));
const OrganizationParticipants = lazy(
    () => import('modules/settings/pages/OrganizationParticipants/organizationParticipants')
);
const ShareFilesView = lazy(() => import('modules/file-sharing/pages/ShareFilesView'));
const AboutReferProgram = lazy(() => import('modules/refer-friend/pages/AboutReferProgram'));
const ReferAFriend = lazy(() => import('modules/refer-friend'));
const CreateReferringPage = lazy(() => import('modules/refer-friend/pages/CreateReferringPage'));
const ReferralCode = lazy(() => import('modules/sign-up/screens/ReferralCode'));
const TypeOfCopy = lazy(() => import('modules/file-sharing/index'));
const GetAccessEmailPage = lazy(() => import('modules/file-sharing/pages/GetAccessEmailPage'));
const GetAccessConfirm = lazy(() => import('modules/file-sharing/pages/GetAccessConfirm'));
const SharedUnauthFolderView = lazy(
    () => import('modules/file-sharing/pages/SharedUnauthFolderView')
);
const SharedAuthFolderView = lazy(() => import('modules/file-sharing/pages/SharedAuthFolderView'));
const DeletingOfProofKeepAccount = lazy(
    () => import('modules/settings/pages/DeletingOfProofKeepAccount')
);
const DeletingMyAccountReasons = lazy(
    () => import('modules/settings/pages/DeletingMyAccountReasons')
);
const DeletingMyAccountConfirm = lazy(
    () => import('modules/settings/pages/DeletingMyAccountConfirm')
);
const Legal = lazy(() => import('modules/settings/pages/Legal'));
const Policy = lazy(() => import('modules/settings/pages/Legal/Policy'));
const Terms = lazy(() => import('modules/settings/pages/Legal/Terms'));
const FAQ = lazy(() => import('modules/settings/pages/FAQ'));
const TechSupport = lazy(() => import('modules/settings/pages/TechSupport'));
const ContactUs = lazy(() => import('modules/settings/pages/ContactUs'));

export const Router = () => {
    return (
        <Suspense fallback={<BackdropLoader isOpen={true} />}>
            <Switch>
                <PublicRoute exact path={Routes.START_SCREEN} component={StartScreen} />
                <PublicRoute exact path={Routes.SIGN_UP} component={SignUp} />
                <PublicRoute
                    exact
                    path={Routes.ORGANIZATION_SIGN_UP}
                    component={OrganizationSignUp}
                />
                <PublicRoute exact path={Routes.LOG_IN} component={LogIn} />
                <PrivateRoute exact path={Routes.PAYMENT_DETAILS_SETUP} component={PaymentInfo} />
                <PrivateRoute path={Routes.WALKTHROUGH} exact component={Walkthrough} />
                <PublicRoute
                    path={`${Routes.RECOVERY_PASSWORD}/:type`}
                    exact
                    component={RecoveryPassword}
                />
                <Route exact path={Routes.UNAUTH_SHARED_FOLDER_VIEW} component={() => <></>} />
                <PrivateRoute path={Routes.DASHBOARD} component={DashboardPage} />
                <PrivateRoute exact path={Routes.EVIDENCE} component={Evidence} />
                <PrivateRoute exact path={Routes.CHECKOUT} component={CheckoutPage} />
                <PrivateRoute exact path={Routes.FOLDER_VIEW} component={FolderView} />
                <PrivateRoute
                    exact
                    path={`${Routes.DELETE_FILES_VIEW}/:folderId` as Routes}
                    component={DeleteFilesView}
                />
                <PrivateRoute
                    exact
                    path={`${Routes.SHARE_TYPE_OF_COPY}/:folderId` as Routes}
                    component={TypeOfCopy}
                />

                <PrivateRoute
                    exact
                    path={`${Routes.WHO_YOU_WANT_SHARE}/:folderId` as Routes}
                    component={WhoYouWantShare}
                />

                <Route
                    exact
                    path={`/:token1${Routes.GET_ACCESS_EMAIL}/:token2` as Routes}
                    component={GetAccessEmailPage}
                />

                <Route exact path={Routes.GET_ACCESS_CONFIRM} component={GetAccessConfirm} />
                <Route
                    exact
                    path={Routes.UNAUTH_SHARED_FOLDER_VIEW}
                    component={SharedUnauthFolderView}
                />
                <Route
                    exact
                    path={Routes.AUTH_SHARED_FOLDER_VIEW}
                    component={SharedAuthFolderView}
                />

                <PrivateRoute
                    exact
                    path={`${Routes.SHARE_FILES_VIEW}/:folderId` as Routes}
                    component={ShareFilesView}
                />
                <PrivateRoute
                    exact
                    path={`${Routes.EXTEND_STORAGE_DATE}/:folderId` as Routes}
                    component={ExtendStorageDateView}
                />
                <PrivateRoute
                    exact
                    path={`${Routes.PROOF_METADATA_VIEW}/:folderId` as Routes}
                    component={ProofMetadataView}
                />
                <PrivateRoute exact path={Routes.FOLDER_MAP_VIEW} component={FolderMapPointsView} />
                <PrivateRoute
                    exact
                    path={Routes.ALL_FOLDERS_MAP_VIEW}
                    component={FoldersMapPointsView}
                />
                <PrivateRoute exact path={Routes.EXTEND_SUCCESS} component={ExtendSuccess} />
                <PrivateRoute exact path={Routes.PAYMENT_METHODS} component={PaymentMethods} />
                <PrivateRoute exact path={Routes.STORED_ACCOUNT} component={StoredAccount} />
                <PrivateRoute
                    exact
                    path={Routes.WALLET_CONGRATULATIONS}
                    component={WalletCongratulations}
                />
                <PrivateRoute
                    exact
                    component={OrganizationParticipants}
                    path={Routes.ORGANIZATION_PARTICIPANTS}
                />
                <PrivateRoute exact path={Routes.ADD_PAYMENT_METHOD} component={AddPaymentMethod} />
                <PrivateRoute exact path={Routes.VEHICLE_RENTAL} component={VehicleRentalPage} />
                <PrivateRoute
                    exact
                    path={Routes.VEHICLE_RENTAL_CAPTURE}
                    component={VehicleRentalCapture}
                />
                <PrivateRoute exact path={Routes.HOTEL_RENTAL} component={HotelRentalPage} />
                <PrivateRoute
                    exact
                    path={Routes.HOTEL_RENTAL_CAPTURE}
                    component={HotelRentalCapture}
                />
                <PrivateRoute exact path={Routes.TRANSACTION_INFO} component={TransactionInfo} />
                <PrivateRoute exact path={Routes.ACCOUNT_INFO} component={AccountInfo} />
                <PrivateRoute exact path={Routes.CHANGE_ADDRESS} component={ChangeAddress} />
                <PrivateRoute exact path={Routes.CHANGE_PASSWORD} component={ChangePassword} />
                <PrivateRoute exact path={Routes.DATE_AND_TIME} component={DateAndTime} />
                <PrivateRoute exact path={Routes.EVENTS_HISTORY} component={EventsHistory} />
                <PrivateRoute exact path={Routes.REFER_A_FRIEND} component={ReferAFriend} />
                <PrivateRoute exact path={Routes.REFERRAL_CODE} component={ReferralCode} />
                <PrivateRoute
                    exact
                    path={'/refer/:type' as Routes}
                    component={CreateReferringPage}
                />
                <PrivateRoute
                    exact
                    path={Routes.ABOUT_REFER_PROGRAM}
                    component={AboutReferProgram}
                />

                <PrivateRoute
                    exact
                    path={Routes.DELETING_OF_PROOFKEEP_ACCOUNT}
                    component={DeletingOfProofKeepAccount}
                />

                <PrivateRoute
                    exact
                    path={Routes.DELETE_MY_ACCOUNT_REASONS}
                    component={DeletingMyAccountReasons}
                />

                <PrivateRoute
                    exact
                    path={Routes.DELETE_MY_ACCOUNT_CONFIRM}
                    component={DeletingMyAccountConfirm}
                />

                <Route exact path={Routes.LEGAL} component={Legal} />
                <Route exact path={Routes.POLICY} component={Policy} />
                <Route exact path={Routes.TERMS} component={Terms} />
                <Route exact path={Routes.FAQ} component={FAQ} />
                <Route exact path={Routes.TECH_SUPPORT} component={TechSupport} />
                <Route exact path={Routes.CONTACT_US} component={ContactUs} />

                <Redirect path="*" to={Routes.START_SCREEN} />
            </Switch>
        </Suspense>
    );
};
