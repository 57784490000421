import { FileView } from 'modules/common/types';
import { GetPaymentPriceResponse } from '../payments/types';

export interface IUnauthSharedFolder {
    situationId: string;
    username: string;
    email: string;
    sharingUrlExpirationDate: string;
    situationName: string;
    sharedDate: string;
    evidences: (FileView & { evidenceId: string })[];
    numberOfViews: number;
    formattedTransactionNumber: string;

    recipientName: string;
    recipientEmail: string;
}

export enum CopyTypes {
    Unauthenticated = 'unauthenticated',
    Authenticated = 'authenticated',
}

export interface CreateShareFolderUrlRequest {
    active?: boolean;

    situationId?: string;
    evidencesIdList: string[];
    sharingUrlExpirationDate: string;
    copyType: CopyTypes;
    authorName?: string;
    recipientName?: string;
    recipientEmail?: string;
}

export interface CreateShareFolderUrlResponse {
    sharingUrl?: string;
    token?: string;
}

export interface SharingPriceResponse {
    paymentNeeded: boolean;
    evidencesIdListPaymentNeeded?: string[];
    paymentDetails?: GetPaymentPriceResponse;
}

export interface SharingPriceRequest {
    evidencesIdList: string[];
    copyType: CopyTypes;
}

export interface SendSharingCodeRequest {
    email: string;
    token: string;
}

export interface CheckSharingCodeRequest {
    codeId: string;
    userValue: string;
}

export interface CheckSharingCodeResponse {
    correct: boolean;
    accessToken: string;
}

export interface GetProofMetadataRequest {
    folderId: string;
    proofId: string;
}
